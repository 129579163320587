@use "../../styles/partials/varible.scss" as *;
.add_button {
  background: #2b71ff;
  margin-inline-end: 0;
  height: 40px;
  border: 1px solid #2b71ff;
  border-radius: 4px;
  padding: 4px 12px;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  font-family: $default_font_family;
  svg {
    width: 20px;
  }
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
.user {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  h3 {
    font-family: $default_font_family;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #101828;
  }
}
.currier_modal_title {
  font-family: $default_font_family;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #667085;
}
.curier_modal_body {
  font-family: $default_font_family;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #101828;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 140px;
}
.icon {
  width: 24px;
  height: 24px;
  background: #eaecf0;
  border: 1px solid #eaecf0;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit_btn {
  background: #ffffff;
  border: 1px solid #eaecf0 !important;
  border-radius: 4px;
  font-family: $default_font_family;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 160%;

  color: #000000;
}
.delete_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  background: rgba(255, 255, 255, 1e-5);
  border: 1px solid rgba(255, 255, 255, 1e-5);
  border-radius: 4px;
  font-family: $default_font_family;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 160%;
  color: #f04438 !important;
}
.search_order {
  position: relative;
  svg {
    position: absolute;
    top: 16px;
    left: 10px;
    font-size: 18px;
    line-height: 14px;
    color: #667085;
  }
  input {
    outline: none;
    padding: 12px 34px;
    width: 250px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.filter_search {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.filtered_order {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  color: #000000;
  font-weight: 600;
  svg {
    color: #475467;
  }
}

//OrderList_table

.head_table {
  background: #f8fafc;
  width: 100%;
  padding: 10px 0px;
  font-family: $default_font_family;
  th {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #475467;
  }
}
.data_img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: $default_font_family;
  gap: 10px;
  img {
    width: 100px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #101828;
  }
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;

    color: #667085;
  }
}
.check {
  width: 20px;
  height: 20px;
  margin: 20px 0px;
}
.tbody {
  td {
    vertical-align: middle;
    p {
      margin: 0;
    }
  }
}
.phone_number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #101828;
}
.transport_type {
  @extend .phone_number;
  display: flex;
  align-items: center;
  gap: 8px;
  i {
    font-size: 12px;
    color: #667085;
  }
}
.standart_status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  border: none;
  background: #dbe8ff;
  border-radius: 999px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #2b71ff;
}
.offline_status {
  @extend .standart_status;
  color: #eaecf0;
  background-color: #475467;
}
.active_status {
  @extend .standart_status;
  color: #eaecf0;
  background: #16b364;
}

.curriers_name {
  padding: 4px 8px;

  cursor: pointer;
  /* 🔅 Background/Default */

  background: #ffffff;
  /* 🔅 Background/Default */
  text-align: center;
  border: 1px solid #ffffff;
  /* Shadows/XS */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  & > div {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.action2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  & > div {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.success {
  font-family: $default_font_family;
  font-weight: 600 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 4px !important;
  gap: 4px;
  background: #e1fbea;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #16b364 !important;
  border-radius: 999px;
}
.onthe_way {
  @extend .success;
  background: #fff6db;
  color: #f79009 !important;
}
.failed {
  @extend .success;
  background: #ffeeed;
  color: #f04438 !important;
}
.picked_up {
  @extend .success;
  background: #dbe8ff;
  color: #2b71ff !important;
}
