@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./partials/varible.scss";
@import "./partials/mixin.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  outline: none !important;
  &:focus {
    outline: none;
  }
}

// .css-929hxt-MuiInputBase-input-MuiInput-input:focus,
// .css-1x51dt5-MuiInputBase-input-MuiInput-input:focus {
//   outline: 0 !important;
//   outline: 0px solid transparent !important;
//   outline-offset: 0px !important;
//   box-shadow: none !important;
//   border-color: none !important;
// }
.currier_modal {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  color: #101828;
}
.custom_modal_width {
  width: 691px !important;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.p-multiselect-panel .p-multiselect-header {
  display: none;
}

.react-flow__handle {
  width: 15px;
  height: 15px;
  background: white;
  border: 3px solid black;
}

.react-flow__edge-path {
  stroke: #000;
}

.react_flow_handle_input_top {
  left: 0;
  top: 50%;
}
.react_flow_handle_input_bottom {
  top: 75%;
  left: 0;
}
#error_field {
  color: #bf1650;
  &::before {
    display: inline;
    content: "⚠ ";
  }
}
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
}

.container {
  max-width: 1400px !important;
  margin: 0px auto !important;
}
.error_filed {
  color: #bf1650;
}
.error_filed::before {
  display: inline;
  content: "⚠ ";
}
.scroll {
  overflow-y: scroll;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }
}
