@import "../../../styles/partials/varible.scss";
.dispatch_assigned_orders {
  background: #ffffff;
  font-family: $default_font_family;
  h1 {
    padding: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #101828;
  }
}
.order_assign {
  padding: 10px;
  background: #ffffff;
  margin: 10px 20px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}
.assign_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_number {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
}
.right_side {
  display: flex;
  align-items: center;
  gap: 6px;
  button {
    padding: 4px 8px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.order_status {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    padding: 4px 8px;
  }
  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    text-align: right;

    /* 🔅 Gray/900 */

    color: #101828;
  }
  .on_the_way {
    background: #fff6db;
    color: #f79009;
    min-width: 80px;
  }
  .fail {
    background: #ffeeed;
    color: #f04438;
    min-width: 80px;
  }
}
