@import "../../../styles/partials/varible.scss";
#dispatch {
  font-family: $default_font_family;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #101828;
  }
  p {
    margin: 5px 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    /* 🔅 Gray/500 */

    color: #667085;
  }
  .rigth_side {
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 4px;
    button {
      border-radius: 4px;
      border: none;
      padding: 3px 10px;
      background: #ffffff;
      border: 1px solid #eaecf0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      &:nth-child(1) {
        background: #f8fafc;
      }
    }
  }
}
