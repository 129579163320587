@import "../../../styles/partials/varible.scss";

.head_table {
  background: #f8fafc;
  width: 100%;
  padding: 10px 0px;
  font-family: $default_font_family;
  th {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #475467;
  }
}
.data_img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: $default_font_family;
  gap: 10px;
  img {
    width: 34px;
    height: 20px;
    object-fit: contain;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #101828;
  }
  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;

    color: #667085;
  }
}
.check {
  width: 20px;
  height: 20px;
  margin: 20px 0px;
}
.tbody {
  td {
    vertical-align: middle;
  }
}
.phone_number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #101828;
}
.standart_status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  border: none;
  background: #dbe8ff;
  border-radius: 999px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 160%;
  color: #2b71ff;
}
.express_status {
  @extend .standart_status;
  background: #eaecf0;
  color: #475467;
}

.curriers_name {
  padding: 4px 8px;
  cursor: pointer;
  /* 🔅 Background/Default */
  background: #ffffff;
  /* 🔅 Background/Default */
  text-align: center;
  border: 1px solid #ffffff;
  /* Shadows/XS */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.some_courriers {
  width: 15px;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.edit {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
}
.setting {
  @extend .edit;
}

.search_order {
  position: relative;
  svg {
    position: absolute;
    top: 16px;
    left: 10px;
    font-size: 18px;
    line-height: 14px;
    color: #667085;
  }
  input {
    outline: none;
    padding: 12px 34px;
    width: 250px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.edit_btn {
  background: #ffffff;
  border: 1px solid #eaecf0 !important;
  border-radius: 4px;
  font-family: $default_font_family;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 160%;

  color: #000000;
}
.delete_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  background: rgba(255, 255, 255, 1e-5);
  border: 1px solid rgba(255, 255, 255, 1e-5);
  border-radius: 4px;
  font-family: $default_font_family;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 160%;
  color: #f04438 !important;
}

.left_side {
  display: flex;
  align-items: center;
  gap: 12px;
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
.detail_name {
  font-family: $default_font_family;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    color: #101828;
    max-width: 140px;
  }
  h5 {
    @extend h4;
    max-width: 100%;
  }
  h3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;
    width: 63px;
    height: 24px;
    background: #dbe8ff;
    border-radius: 999px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    color: #2b71ff;
  }
}
