@import "../../../styles/partials/varible.scss";
.forms_elements {
  background: #f8fafc;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 20px;
}
.driver_details {
  font-family: $default_font_family;
  border-bottom: 1px solid #eaecf0;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
  }
}
.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #eaecf0;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
  input,
  select {
    width: 370px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    outline: none;
    padding: 10px 40px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  textarea {
    width: 370px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    outline: none;
    padding: 10px 20px;
    height: 100px !important;
  }
}
.name_input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
    margin: 0;
  }
}
.email_input {
  position: relative;
  i,
  svg {
    font-size: 12px;
    position: absolute;
    top: 19px;
    left: 16px;
    color: #667085;
    width: 12px;
    height: 12px;
  }
  input {
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }
  select {
    border: unset;
    width: 370px;
    padding: 0.75rem;
    appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAJ1BMVEX///8BAQHOzs64uLidnZ3g4OCmpqbZ2dnq6urX19exsbGAgID19fXP5vvMAAACP0lEQVR4nO3PWXLDMAwEUUnZl/ufN3ESx5ssURRBYFDd30DVvGEgIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiGoZX7wEVbdr8Mj5a7TBrGp/Lj1/GUY44fW8uJh6AasTpZ3Mh8ReoRZz+NhcRj0Al4vS/uYB4AuoQp7PNq8RzoApxuti8QrwEahCnq82LxOtjBeK2zW831+GJt8Dxfen+SY04A3xY/hAjbgeKEWuAUsQ6oBCxFihDrAeKEPcAJYj7gALEvcDwxP3A4MQWwNDENsDAxFbAsMR2wKDElsCQxLbAgMTWwHDE9sBgRAtgKKINMBDRChiGaAcMQrQEhiDaAgMQrYHuRHugM7EH0JXYB+hI7AV0I/YDOhF7Al2IfYEOxN7A7sT+wM5ED2BXog+wI9EL2I3oB+xE9AR2IfoCOxC9geZEf6AxMQLQlBgDaEiMAjQjxgEaESMBTYixgAbEaMDmxHjAxsSIwKbEmMCGxKjAZsS4wEbEyMAmxNjABsTowN3E+MCdRAXgLqIGcAdRBVhN1AFWEpWAVUQtYAVRDbiZqAfcSFQEbiJqAjcQVYHFRF1gIVEZWETUBhYQ1YGrRH3gCjEDcJGYA7hAzAK8S8wDvEPMBJwlfqQCzhJzAUuI4sB1ojxwjZgAuExMAVwiJgHeJ6YB3iMmAs4TUwHniMmAt8R0wGtiQuAlMSXwnJgUeCKmBR6JiYG/xNTAAzE5cBg+vQcQERERERERERERERERERERERERERERERERyfcFotsTGeQ6WlYAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
  }
}
.calendar {
  width: 370px !important;
}
.password {
  position: relative;
  svg {
    position: absolute;
    top: 16px;
    right: 8px;
    cursor: pointer;
  }
}

.btns_group {
  display: flex;
  align-items: center;
  gap: 12px;
}
.cancel_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 30px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 4px;
}
.save_btn {
  @extend .cancel_btn;
  background: #2b71ff;
  border: 1px solid #2b71ff;
  color: #ffffff;
  & :disabled {
    background-color: #4067b4b5;
    cursor: not-allowed;
  }
}

.concat_input {
  display: flex;
  width: 370px;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  overflow: hidden;

  input {
    border: none;
    outline: none;
    border-radius: 0;
    appearance: none !important;
    &::-webkit-inner-spin-button {
      display: none;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
  select {
    border: none;
    outline: none;
    border-radius: 0;
    appearance: none !important;
    &::-webkit-inner-spin-button {
      display: none;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
}
.radio {
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  border-bottom: 1px solid #eaecf0;
}
.radio_input {
  width: 370px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
  input:checked {
    & ~ div {
      background: #2b71ff;
      border: 1px solid #2b71ff;
    }
  }
}

.items {
  margin: 10px 0px;
  input {
    width: 100%;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
  select {
    width: 100%;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.right_side {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 !important;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  input {
    appearance: none;
    width: 100%;
    padding: 8px;
    border-radius: 0px;
    &::-webkit-inner-spin-button {
      display: none;
    }
  }
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    width: 40px;
    height: 41px;
    background: #f8fafc;
    font-size: 14px;
    border-left: 1px solid #d0d5dd;
  }
}

.delete_items {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  /* 🔅 Background/Default */

  background: #ffffff;
  /* 🔅 Gray/200 */

  border: 1px solid #eaecf0;
  border-radius: 4px;
}
.add_item {
  font-family: $default_font_family;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  margin: 12px 0px;
}
.total_w {
  font-family: $default_font_family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
}
.total {
  font-family: $default_font_family;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #d0d5dd;
  border-radius: 4px;

  h5 {
    padding: 0px;
    margin: 0;
    // width: 40px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #f8fafc;
  }
  button {
    padding: 0px;
    width: 40px;
    height: 40px;
    background: #f8fafc;
    border-left: 1px solid #d0d5dd;
    font-size: 15px;
  }
}
.times {
  width: 365px;
  display: flex;
  justify-content: space-between !important;
  gap: 5px;
  input {
    font-size: 0.8rem;
    color: #667085;
  }
}
