@import "../../../styles/partials/varible.scss";
.nav {
  background-color: #141414;
  height: 80px;
  font-family: $default_font_family;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  @media (max-width: 1000px) {
    display: none;
  }
}
.logo {
  width: 106px;
  height: 32px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.list {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0;
  margin: 0;
  gap: 30px;
  li {
    list-style: none;
    a {
      text-decoration: none;
      font-size: 12px;
      color: #667085;
      padding: 10px 12px;
      border-radius: 4px;
    }
  }
}
.active {
  background-color: #667085;
  color: white !important;
}

.right_list {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  li {
    svg {
      color: #98a2b3;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
.avatar {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mobile_menu {
  display: none !important;
  @media (max-width: 1000px) {
    display: block !important;
  }
}
