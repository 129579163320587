@import "../../styles/partials/varible.scss";
.add_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  background: #2b71ff;
  border: 1px solid #2b71ff;
  color: #fff;
  border-radius: 4px;
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
.partners {
  padding: 20px 0px;
  h2 {
    font-family: $default_font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #101828;
    margin: 0;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
    margin: 4px 0px;
  }
}
