@import "../../styles/partials/varible.scss";
aside {
  padding: 30px 0px;
  font-family: $default_font_family;

  .order_card {
    border: 1px solid #eaecf0;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    .card_icons {
      box-sizing: border-box;
      width: 56px;
      height: 56px;
      background: #fff2e4;
      border: 1px solid #fff2e4;
      border-radius: 999px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ef6820;
      }
    }
    .card_detail {
      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 120%;
        color: #101828;
      }
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #667085;
      }
    }
  }
}
