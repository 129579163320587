.element {
  min-width: 358px;
  min-height: 146px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.08),
    0px 4px 6px -2px rgba(0, 0, 0, 0.03);
  border: 1px solid #eaecf0;
  border-radius: 8px;
  p {
    margin-bottom: 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
  }
  .iconWrapper {
    width: 32px;
    height: 32px;
    background-color: #dbe8ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .body {
    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #101828;
    }
    .info {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #667085;
    }
    .valueWrapper {
      background: #ffffff;
      border: 1px solid #eaecf0;
      border-radius: 4px;
      height: 40px;
      display: flex;
      input {
        flex-basis: 90%;
        border: none;
        height: 100%;
        background: transparent;
        border-color: transparent;
        padding: 5px 10px;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .valueType {
        background-color: #f8fafc;
        height: 100%;
        flex-basis: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        color: #101828;
      }
    }
    .betweenWrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .valueWrapper {
        display: inline-flex;
        width: 145px;
        input {
          width: 100%;
        }
        p {
          flex-basis: 30%;
        }
      }
    }
    .joinWrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        flex-basis: 20% !important;
      }
    }
  }
  .createBtn,
  .createBtnLeft {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    right: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.3s;
  }
  .createBtnLeft {
    left: -60px;
    transform: translateX(20px);
  }
  .createBtn {
    transform: translateX(-20px);
  }
  &:hover {
    .createBtn,
    .createBtnLeft {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .editBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .options {
    width: 150px;
    padding: 10px 0;
    background-color: #fff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    position: absolute;
    top: -60px;
    right: 0px;
    p {
      color: red;
      padding: 4px;
      &:hover {
        background: #eaecf0;
      }
    }
  }
}
