@import "../../styles/partials/varible.scss";
.login_user {
  padding: 20px 0px;
  font-family: $default_font_family;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
    margin: 0;
  }
}
