@import "../../../styles/partials/varible.scss";
.dispatch_drivers {
  font-family: $default_font_family;
  height: 100vh;
  overflow-y: scroll;
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #101828;
    padding: 10px 20px;
  }
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    background: #ffffff;
  }
}
.card_avatar {
  padding: 10px;
  display: flex;
  background: #f8fafc;
  border-radius: 4px;
  justify-content: space-evenly;
  margin: 10px 10px;
  align-items: center;
}
.user_name {
  width: 40px;
  height: 40px;
  background: #f3f1ff;
  border: 1px solid #ffffff;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:nth-child(1) {
    background: #f3f1ff;
  }
  &:nth-child(2) {
    background: #e3fff8;
    color: #1570ef;
  }
  &:nth-child(3) {
    background: #e3fff8;
    color: #0e9384;
  }
  &:nth-child(4) {
    background: #f0fde2;
    color: #4ca30d;
  }
}
.user_table {
  h3 {
    max-width: 142px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    color: #000000;
    margin: 0;
  }
  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    color: #667085;
    margin: 0;
  }
}

.count {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;
  width: 24px;
  height: 24px;
  background: #eaecf0;
  border-radius: 50%;
  p {
    margin: 0;
  }
}
