@import "../../styles/partials/varible.scss";
#dashborard_order {
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 20px;
  font-family: $default_font_family;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #000000;
  }
}

.order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
  }
}

#data_table {
  padding: 0px 0;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: 433px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  thead {
    background: #f8fafc;

    tr {
      th {
        position: sticky;
        top: 0;
        font-style: normal;
        z-index: 1;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #475467;
        background-color: #f8fafc;
      }
    }
  }
  tbody {
    tr {
      th,
      td {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 160%;

        color: #101828;
        .avatar {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          .avatar_img {
            box-sizing: border-box;
            width: 32px;
            height: 32px;
            border: 1px solid #ffffff;
            border-radius: 999px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
          h5 {
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 160%;
            color: #101828;
          }
        }
      }
    }
  }
}
