@import "../../../styles/partials/varible.scss";
.heading {
  font-family: $default_font_family;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #101828;
  }
  p {
    margin: 4px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
  }
}
.btns_group {
  display: flex;
  align-content: center;
  gap: 10px;
  justify-content: center;
}
.cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  color: #101828;
}
.save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background: #2b71ff;
  border: 1px solid #2b71ff;
  border-radius: 4px;
  color: #ffffff;
}
.prefence_forms {
  margin: 30px 0px;
  background: #f8fafc;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  padding: 30px 20px;
  h2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
  }
}

.choose_country {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $default_font_family;
  select {
    appearance: none;
    padding: 0px;
    width: 425.5px;
    padding: 10px;
    background: #f8fafc;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAJ1BMVEX///8BAQHOzs64uLidnZ3g4OCmpqbZ2dnq6urX19exsbGAgID19fXP5vvMAAACP0lEQVR4nO3PWXLDMAwEUUnZl/ufN3ESx5ssURRBYFDd30DVvGEgIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiGoZX7wEVbdr8Mj5a7TBrGp/Lj1/GUY44fW8uJh6AasTpZ3Mh8ReoRZz+NhcRj0Al4vS/uYB4AuoQp7PNq8RzoApxuti8QrwEahCnq82LxOtjBeK2zW831+GJt8Dxfen+SY04A3xY/hAjbgeKEWuAUsQ6oBCxFihDrAeKEPcAJYj7gALEvcDwxP3A4MQWwNDENsDAxFbAsMR2wKDElsCQxLbAgMTWwHDE9sBgRAtgKKINMBDRChiGaAcMQrQEhiDaAgMQrYHuRHugM7EH0JXYB+hI7AV0I/YDOhF7Al2IfYEOxN7A7sT+wM5ED2BXog+wI9EL2I3oB+xE9AR2IfoCOxC9geZEf6AxMQLQlBgDaEiMAjQjxgEaESMBTYixgAbEaMDmxHjAxsSIwKbEmMCGxKjAZsS4wEbEyMAmxNjABsTowN3E+MCdRAXgLqIGcAdRBVhN1AFWEpWAVUQtYAVRDbiZqAfcSFQEbiJqAjcQVYHFRF1gIVEZWETUBhYQ1YGrRH3gCjEDcJGYA7hAzAK8S8wDvEPMBJwlfqQCzhJzAUuI4sB1ojxwjZgAuExMAVwiJgHeJ6YB3iMmAs4TUwHniMmAt8R0wGtiQuAlMSXwnJgUeCKmBR6JiYG/xNTAAzE5cBg+vQcQERERERERERERERERERERERERERERERERyfcFotsTGeQ6WlYAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
    margin: 0;
  }
}

.select_distance {
  width: 127px;
  height: 32px;
  display: flex;
  background: #eaecf0;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;
  button {
    background: none;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
}
.active_btn {
  width: 35px;
  height: 24px;
  background: #ffffff !important;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 3px 4px !important;
  font-size: 12px !important;
}
.distance_unit {
  width: 500px;
}
.distance {
  display: flex;
  align-items: center;
}

// @media (max-width: 768px) {
//   .choose_country {
//     flex-direction: column;
//     select {
//       width: 100%;
//     }
//   }
// }
.active_check {
  background: #dbe8ff !important;
  /* 🔅 Primary/500 */

  border: 1px solid #2b71ff !important;
}

.check_box {
  width: 400px;
}
.text_btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  p {
    margin: 3px 0px;
    &:nth-child(1) {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      color: #101828;
    }
  }
}
.work_hours {
  width: 287px;
  height: 32px;
  display: flex;
  background: #eaecf0;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  gap: 10px;
  button {
    background: none;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
}

.working_active_btn {
  width: 125px;
  height: 24px;
  background: #ffffff !important;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 3px 4px !important;
  font-size: 12px !important;
}
