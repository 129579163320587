@import "../../styles/partials/varible.scss";
.left_side {
  font-family: $default_font_family;
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 20px;
  svg {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.left_text {
  font-family: $default_font_family;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    margin: 0;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
    margin: 4px 0px;
  }
}
