@import "../../../styles/partials/varible.scss";
.total_score {
  font-family: $default_font_family;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 30px;
  cursor: pointer;
}
.top_side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #000000;
  }
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    color: #16b364;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;

    height: 24px;
    background: #e1fbea;
    border-radius: 999px;
    margin: 0;
  }
}
.total_price {
  padding-top: 12px;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height, or 122% */

    letter-spacing: -0.02em;

    /* Base/Black */

    color: #000000;
  }
}
