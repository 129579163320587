@import "../../../styles/partials/varible.scss";
.forms_elements {
  background: #f8fafc;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 20px;
}
.driver_details {
  font-family: $default_font_family;
  border-bottom: 1px solid #eaecf0;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
  }
}
.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #eaecf0;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
  input,
  select {
    width: 370px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    outline: none;
    padding: 10px 30px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
.name_input {
  display: flex;
  flex-direction: column;
  gap: 5px;
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
    margin: 0;
  }
}
.email_input {
  position: relative;
  svg {
    position: absolute;
    top: 16px;
    left: 8px;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  select {
    border: unset;
    width: 370px;
    padding: 0.75rem;
    appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAJ1BMVEX///8BAQHOzs64uLidnZ3g4OCmpqbZ2dnq6urX19exsbGAgID19fXP5vvMAAACP0lEQVR4nO3PWXLDMAwEUUnZl/ufN3ESx5ssURRBYFDd30DVvGEgIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiIiGoZX7wEVbdr8Mj5a7TBrGp/Lj1/GUY44fW8uJh6AasTpZ3Mh8ReoRZz+NhcRj0Al4vS/uYB4AuoQp7PNq8RzoApxuti8QrwEahCnq82LxOtjBeK2zW831+GJt8Dxfen+SY04A3xY/hAjbgeKEWuAUsQ6oBCxFihDrAeKEPcAJYj7gALEvcDwxP3A4MQWwNDENsDAxFbAsMR2wKDElsCQxLbAgMTWwHDE9sBgRAtgKKINMBDRChiGaAcMQrQEhiDaAgMQrYHuRHugM7EH0JXYB+hI7AV0I/YDOhF7Al2IfYEOxN7A7sT+wM5ED2BXog+wI9EL2I3oB+xE9AR2IfoCOxC9geZEf6AxMQLQlBgDaEiMAjQjxgEaESMBTYixgAbEaMDmxHjAxsSIwKbEmMCGxKjAZsS4wEbEyMAmxNjABsTowN3E+MCdRAXgLqIGcAdRBVhN1AFWEpWAVUQtYAVRDbiZqAfcSFQEbiJqAjcQVYHFRF1gIVEZWETUBhYQ1YGrRH3gCjEDcJGYA7hAzAK8S8wDvEPMBJwlfqQCzhJzAUuI4sB1ojxwjZgAuExMAVwiJgHeJ6YB3iMmAs4TUwHniMmAt8R0wGtiQuAlMSXwnJgUeCKmBR6JiYG/xNTAAzE5cBg+vQcQERERERERERERERERERERERERERERERERyfcFotsTGeQ6WlYAAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
  }
}
.password {
  position: relative;
  svg {
    position: absolute;
    top: 16px;
    right: 8px;
    cursor: pointer;
  }
}

.btns_group {
  display: flex;
  align-items: center;
  gap: 12px;
}
.cancel_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 30px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 4px;
}
.save_btn {
  @extend .cancel_btn;
  background: #2b71ff;
  border: 1px solid #2b71ff;
  color: #ffffff;
  & :disabled {
    background-color: #4067b4b5;
    cursor: not-allowed;
  }
}
