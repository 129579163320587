@import "../../styles/partials/varible.scss";
.register {
  font-family: $default_font_family;
}
.left_side {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  @media (max-width: 768px) {
    align-items: center;
  }
}
.head {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  img {
    width: 100px;
    height: 40px;
    object-fit: contain;
  }
}
.title {
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #667085;
  }
}
.google_login {
  width: 100%;
  padding: 20px 0px;
  button {
    width: 100%;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 160%;
    color: #000000;
    @media (max-width: 768px) {
      width: auto;
      margin: 0 auto;
    }
    img {
      width: 20px;
    }
  }
}

.forms {
  width: 100%;
  padding-top: 20px;
  @media (max-width: 768px) {
    width: auto;
  }
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
    padding: 4px 0px;
  }
  input {
    padding: 8px 40px;
    width: 100%;
    border: none;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
    outline: 1px solid #eaecf0;
  }
  button {
    margin-top: 10px;
    width: 100%;
    border: none;
    padding: 10px 0px;
    background: #2b71ff;
    color: #ffffff;
    border: 1px solid #2b71ff;
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: flex;
    &:disabled {
      background: #d0d5dd;
      border: 1px solid #d0d5dd;
      color: #98a2b3;
      cursor: not-allowed;
    }
  }
}
.form_input {
  position: relative;
  margin-bottom: 12px;
}
.email {
  top: 16px;
  position: absolute;
  left: 20px;
  img {
    width: 12px;
  }
}
.status {
  position: absolute;
  top: 16px;
  right: 12px;
  svg {
    color: red;
  }
}
.error {
  border: 1px solid red !important;
}
.success {
  border: 1px solid #16b364 !important;
}
.un_lock {
  position: absolute;
  top: 13px;
  left: 20px;
}
.lock {
  @extend .un_lock;
}
.eye {
  position: absolute;
  top: 16px;
  right: 29px;
  cursor: pointer;
  svg {
    width: 12px;
  }
}

.bg_color {
  background: #fde298;
  height: 100vh;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
}
.check_data {
  display: flex;
  width: 150px;
  gap: 5px;
  align-items: center;
  input[type="checkbox"] {
    width: 20px;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    appearance: auto;
    &:focus {
      --tw-ring-color: #1c64f200;
    }
  }

  p {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #101828;
  }
}

.forget_password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recover {
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: right;
    color: #667085;
  }
  a {
    color: #2b71ff;
    text-decoration: none;
    @extend span;
  }
}
.sign_up {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;

    text-align: center;

    color: #667085;
    a {
      color: #2b71ff;
      text-decoration: none;
      @extend span;
    }
  }
}
.stepfourform {
  max-width: 500px;
  margin: 0 auto;
}
.otp_pending {
  display: flex;
  height: 2.5rem;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  background: #d0d5dd;
  width: 100%;
  margin: 20px 0px;
  color: #98a2b3;
  font-family: $default_font_family;
}
.otp_success {
  @extend .otp_pending;
  border: 1px solid #2b71ff !important;
  background: #2b71ff !important;
  color: #eaecf0;
  font-family: $default_font_family;
}
.pending_time {
  color: #667085;
  font-size: 0.875rem;
  font-family: Inter;
  line-height: 160%;
}

.selected_typeof_buiosness {
  color: #667085;
  font-size: 0.75rem;
  font-family: Inter;
  line-height: 160%;
}

.step_four_forms {
  input {
    width: 100%;
    padding: 0.5rem 1rem;
  }
  select {
    width: 100%;
    padding: 0.5rem 1rem;
  }
  label {
    color: #101828;
    /* Paragraph/MD/Semibold */
    font-size: 0.875rem;
    font-family: Inter;
    font-weight: 600;
    line-height: 160%;
  }
}
.country_form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  select {
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.select_city {
  margin: 10px 0px;
  @extend .country_form;
}
.company_name_filed {
  margin: 10px 0px;
  @extend .country_form;
  input {
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.contact_person_name_filed {
  margin: 10px 0px;
  @extend .country_form;
  input {
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.phone_fileds {
  @extend .country_form;
}
.fileds_of_phones {
  display: flex;
  width: 100%;
  select {
    width: 25%;
  }
  input {
    width: 75%;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.form_err {
  color: red;
  font-size: 0.75rem;
  font-family: Inter;
  line-height: 160%;
}
