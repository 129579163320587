@import "../../../styles/partials/varible.scss";

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f8fafc !important;
  border-radius: 4px !important;
  color: #344054 !important;
  border: none;
}
.nav-link {
  font-family: $default_font_family !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 160% !important;
  color: #000000 !important;
  text-align: start !important;
  margin: 6px 0px !important;
  padding: 10px !important;
}
.br_bottom {
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.check_btn {
  display: flex;
  width: 400px;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  padding: 0px 10px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  border-radius: 8px;
  margin: 10px 0px;
}
