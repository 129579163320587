.element {
  border: 1px dashed #d0d5dd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px;
  width: 358px;
  .iconWrapper {
    background: #dbe8ff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    text-align: center;
    line-height: 22px;

    &:first-child {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 14px;
    }
    &:last-child {
      font-weight: 400;
      font-size: 14px;
      color: #667085;
      margin-bottom: 0;
    }
  }
  button {
    align-self: stretch;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
