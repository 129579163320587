.element {
  width: 416px;
  background: #f8fafc;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  &.sub {
    margin-left: 16px;
  }
  .searchWrapper {
    height: 64px;
    display: flex;
    justify-content: space-between;
    padding: 21px 16px;
    border-bottom: 1px solid #eaecf0;
    .left {
      display: inline-flex;
      align-items: center;
      flex-basis: 90%;
      gap: 12px;
      input {
        border: none;
        width: 90%;
        height: 22px;
        background: transparent;
        border-color: transparent;
        padding: 0;
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          top: 0;
          width: 2px;
          height: 10px;
          background-color: gray;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .right {
      flex-basis: 10%;
      .tag {
        width: 33px;
        height: 24px;
        background: #eaecf0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
      }
    }
  }
  .content {
    padding: 8px;
    max-height: 400px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e4e4e4;
      border-radius: 100px;
    }
    .category {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      padding: 9px 16px;
      cursor: pointer;
      transition: 0.3s;
      border: 1px solid transparent;
      border-radius: 4px;
      &:hover {
        background-color: #eaecf0;
        border-color: #eaecf0;
      }
      .left {
        display: flex;
        gap: 12px;
        align-items: center;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .title {
    margin-left: 24px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    color: #98a2b3;
  }
}
