@import "../../styles/partials/varible.scss";
.order {
  font-family: $default_font_family;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #101828;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
  }
}
.buttons {
  display: flex;
  align-items: center;
  gap: 12px;
}
.btn_export {
  background: #ffffff;
  padding: 4px 12px;
  border: 1px solid #eaecf0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
}
.add_button {
  background: #2b71ff;
  border: 1px solid #2b71ff;
  border-radius: 4px;
  padding: 4px 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  svg {
    width: 20px;
  }
}
