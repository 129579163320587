.element {
  //substract the height of header
  min-height: calc(100vh - 100px);
  width: 100%;
  background: #f8fafc;
  .content {
    width: 100%;
    height: calc(100vh - 100px - 148px);
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
