.element {
  height: 148px;
  padding: 48px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  background-color: #fff;
  .left {
    p:first-child {
      font-size: 24px;
      line-height: 28.8px;
      font-weight: 500;
    }
    p:last-child {
      color: #667085;
      font-weight: 400;
      font-size: 12px;
      line-height: 19.2px;
    }
  }
  .right {
    display: flex;
    gap: 12px;
    .button {
      height: 40px;
      padding: 10px 16px;
      border: 1px solid #eaecf0;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 19.2px;
      &.draft {
        background-color: #6c6c6c;
        color: #98a2b3;
      }
      &:disabled {
        background-color: #d0d5dd;
        color: #98a2b3;
      }
      &.active {
        color: #fff;
        background: #2b71ff;
      }
    }
  }
}
