@import "../../../styles/partials/varible.scss";
.search_order {
  position: relative;
  svg {
    position: absolute;
    top: 16px;
    left: 10px;
    font-size: 18px;
    line-height: 14px;
    color: #667085;
  }
  input {
    outline: none;
    padding: 12px 34px;
    width: 250px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 4px;
  }
}
.left_side {
  font-family: $default_font_family;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #101828;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #667085;
  }
}
